import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, DollarCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import {
  Input,
  Table,
  Button,
  Select,
  Tooltip,
  Popconfirm,
  Divider,
  Drawer,
  Tag,
  notification,
  Avatar,
  Modal
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { renderDate } from '../../utils/functions';
import api from '../../../api/api';
import {
  profileAtom,
  timezoneAtom,
} from '../../../atoms/Atoms';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';

function AccountRequests() {
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const width = window.innerWidth;
  const timezone = useRecoilValue(timezoneAtom);
  const userProfile = useRecoilValueLoadable(profileAtom);
  const [loading, setLoading] = useState(false);
  const [userRequests, setUserRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(0);
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const isAdmin =
    userProfile.contents &&
    userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin ||
    (userProfile?.contents &&
      (userProfile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        userProfile?.contents?.roles?.includes('CustomerSupport')));

  if (!isAdmin && !isCustomerService) {
    history.push('/');
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function search(val) {
    setSearchTerm(val);
    setLoading(true);
    api.post('oauth/account/requests', {
      Query: val,
      Type: selectedTypeFilter
    }).then((response) => {
      setLoading(false);
      if (response.data.error) {
        showMessage(response.data.error);
      } else {
        setUserRequests(response.data.data);
      }
    }).catch((error) => {
      setLoading(false);
      showMessage(error.message);
    });
  }

  function approve() {
    setLoading(true);
    api.post(`oauth/account/request/approve/${selectedProfile.id}`, {})
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          showMessage(response.data.message);
          setSelectedProfile(null);
          search(searchTerm);
        }
      }).catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  function reject() {
    setLoading(true);
    api.post('oauth/account/request/reject', {
      AccountRequestId: selectedProfile.id,
      Message: rejectionMessage
    }).then((response) => {
      setLoading(false);
      if (response.data.error) {
        showMessage(response.data.error);
      } else {
        showMessage(response.data.message);
        setSelectedProfile(null);
        setOpenRejectionModal(false);
        search(searchTerm);
      }
    }).catch((error) => {
      setLoading(false);
      showMessage(error.message);
    });
  }

  function update() {
    setLoading(true);
    api.put('oauth/account/request', requestDetails)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          showMessage(response.data.error);
        } else {
          showMessage(response.data.message);
          search(searchTerm);
        }
      }).catch((error) => {
        setLoading(false);
        showMessage(error.message);
      });
  }

  useEffect(() => {
    setRejectionMessage('');
    setRequestDetails(selectedProfile);
  }, [selectedProfile]);

  useEffect(() => {
    search(searchTerm);
  }, [selectedTypeFilter]);

  useEffect(() => {
    search('');
  }, []);

  return (
    <div className="m-4 mt-2">
      <PageHeader
        title={t('account_requests')}
      />
      <div className="flex">
        <Input.Search
          placeholder={t('search_customer_placeholder')}
          allowClear
          className="mr-2"
          loading={loading}
          enterButton={t('search')}
          size="large"
          onSearch={(value) => search(value)}
        />
        <Select
          listHeight={500}
          className="w-40 mt-1"
          onChange={(obj) => setSelectedTypeFilter(obj)}
          value={selectedTypeFilter}
        >
          <Option value={0}>{t('pending')}</Option>
          <Option value={1}>{t('approved')}</Option>
          <Option value={2}>{t('rejected')}</Option>
          <Option value={3}>{t('registered')}</Option>
          <Option value={4}>{t('all')}</Option>
        </Select>
      </div>
      <Table
        className="mt-2"
        bordered
        dataSource={userRequests}
        loading={loading}
        columns={[
          {
            title: t('business_name'),
            key: 'businessName',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{row.businessName}</span>,
          },
          {
            title: t('name'),
            key: 'completename',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{`${row.firstName ? row.firstName : ''} ${row.lastName ? row.lastName : ''}`.trim()}</span>,
          },
          {
            title: t('email'),
            key: 'email',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{row.email}</span>,
          },
          {
            title: t('phone'),
            key: 'phone',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{row.phoneNumber}</span>,
          },
          {
            title: t('requested_on'),
            key: 'requestedon',
            align: 'center',
            className: 'text-xs',
            render: (row) => <span>{renderDate(row.createdAt, timezone)}</span>,
          },
          {
            title: t('status'),
            key: 'requestedon',
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <>
                {row.rejected && (
                  <Tag color="red">{t('rejected')}</Tag>
                )}
                {(!row.rejected && row.linkExpirationDate && !row.associatedUserId) && (
                  <Tag color="green">{t('approved')}</Tag>
                )}
                {(!row.rejected && !row.linkExpirationDate) && (
                  <Tag>{t('pending')}</Tag>
                )}
                {row.associatedUserId && (
                  <Tag color="green">{t('registered')}</Tag>
                )}
              </>
            ),
          },
          {
            title: '',
            key: 'viewdetails',
            align: 'center',
            className: 'text-xs',
            render: (row) =>
              <Button
                size="small"
                type="primary"
                onClick={() => setSelectedProfile(row)}
              >
                {t('view')}
              </Button>,
          },
        ]}
      />
      <Drawer
        title={t('request_details')}
        placement="right"
        className="overflow-y-hidden"
        closable={!loading}
        onClose={() => setSelectedProfile(null)}
        open={selectedProfile}
        width={width < 1024 ? width : 500}
      >
        <div className="overflow-y-hidden">
          <div className="flex mb-4 float-right space-x-2">
            <Popconfirm
              title={t('are_you_sure_approve_request')}
              okText={t('yes')}
              disabled={requestDetails?.associatedUserId}
              cancelText={t('no')}
              onConfirm={() => approve()}
            >
              <Button
                loading={loading}
                disabled={requestDetails?.associatedUserId}
                size="small"
                type="primary"
              >
                {t('approve')}
              </Button>
            </Popconfirm>
            <Popconfirm
              disabled={requestDetails?.associatedUserId}
              title={t('are_you_sure_reject_request')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => setOpenRejectionModal(true)}
            >
              <Button
                loading={loading}
                disabled={requestDetails?.associatedUserId}
                size="small"
                type="primary"
                danger
              >
                {t('reject')}
              </Button>
            </Popconfirm>
          </div>
          <Divider />
          <div className="space-y-4 overflow-y-auto">
            <div>
              <strong>{t('business_name')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  businessName: obj.target.value
                })}
                value={requestDetails?.businessName}
              />
            </div>
            <div>
              <strong>{t('first_name')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  firstName: obj.target.value
                })}
                value={requestDetails?.firstName}
              />
            </div>
            <div>
              <strong>{t('last_name')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  lastName: obj.target.value
                })}
                value={requestDetails?.lastName}
              />
            </div>
            <div>
              <strong>{t('email')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  email: obj.target.value
                })}
                value={requestDetails?.email}
              />
            </div>
            <div>
              <strong>{t('phone_number')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  phoneNumber: obj.target.value
                })}
                value={requestDetails?.phoneNumber}
              />
            </div>
            <div>
              <strong>{t('gender')}</strong>
              <br />
              <Select
                listHeight={500}
                disabled={loading || requestDetails?.associatedUserId}
                className="w-full"
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  gender: obj
                })}
                value={requestDetails?.gender}
              >
                <Option value={0}>{t('male')}</Option>
                <Option value={1}>{t('female')}</Option>
                <Option value={2}>{t('not_say')}</Option>
              </Select>
            </div>
            <div>
              <strong>{t('language')}</strong>
              <br />
              <Select
                listHeight={500}
                disabled={loading || requestDetails?.associatedUserId}
                className="w-full"
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  language: obj
                })}
                value={requestDetails?.language}
              >
                <Option value={0}>{t('english')}</Option>
                <Option value={1}>{t('spanish')}</Option>
              </Select>
            </div>
            <div>
              <strong>{t('address_line_1')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  addressLine1: obj.target.value
                })}
                value={requestDetails?.addressLine1}
              />
            </div>
            <div>
              <strong>{t('address_line_2')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  addressLine2: obj.target.value
                })}
                value={requestDetails?.addressLine2}
              />
            </div>
            <div>
              <strong>{t('city')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  city: obj.target.value
                })}
                value={requestDetails?.city}
              />
            </div>
            <div>
              <strong>{t('state')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  state: obj.target.value
                })}
                value={requestDetails?.state}
              />
            </div>
            <div>
              <strong>{t('country')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  country: obj.target.value
                })}
                value={requestDetails?.country}
              />
            </div>
            <div>
              <strong>{t('external_client_id')}</strong>
              <Input
                disabled={loading || requestDetails?.associatedUserId}
                onChange={(obj) => setRequestDetails({
                  ...requestDetails,
                  externalClientId: obj.target.value
                })}
                value={requestDetails?.externalClientId}
              />
            </div>
            <div className="float-right">
              <Button
                loading={loading}
                disabled={requestDetails?.associatedUserId}
                type="primary"
                onClick={() => update()}
              >
                {t('update')}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <Modal
        title={t('reject_account_request')}
        open={openRejectionModal}
        onCancel={() => setOpenRejectionModal(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => setOpenRejectionModal(false)}
          >
            {t('close')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={loading}
            onClick={() => reject()}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <div>
          <strong>{t('message_optional')}</strong>
          <Input
            disabled={loading}
            onChange={(obj) => setRejectionMessage(obj.target.value)}
            value={rejectionMessage}
          />
        </div>
      </Modal>
    </div>
  );
}

export default AccountRequests;
