import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Image,
  Input,
  notification,
  Row,
  Switch,
  Table,
  Tabs,
  Typography,
  Tooltip,
  Spin,
  Tag,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  CloudDownloadOutlined, LinkOutlined, MinusOutlined, PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { useJsonToCsv } from 'react-json-csv';
import { Disclosure, Tab } from '@headlessui/react';
import {
  businessAtom,
  businessProfileAtom,
  productEditAtom,
  profileAtom,
  refreshSearchFiltersAtom,
  timezoneAtom
} from '../../../../atoms/Atoms';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { convertTaxRule } from '../../../utils/functions';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';

function Search() {
  const { Option } = Select;
  const { saveAsCsv } = useJsonToCsv();
  const [showAssigned, setShowAssigned] = useState(true);
  const store = useRecoilValue(businessAtom);
  const [results, setResults] = useState(null);
  const setProduct = useSetRecoilState(productEditAtom);
  const timezone = useRecoilValue(timezoneAtom);
  const [loading, setLoading] = useState(false);
  const [key] = useState(null);
  const businessProfile = useRecoilValue(businessProfileAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    profile.contents && profile.contents.roles.includes('SuperAdmin');
  const isPanza =
    profile.contents && profile.contents.email.includes('gopanza.com');
  const { t, i18n } = useTranslation();
  const defaultFilters = [
    { name: t('clear_filters'), tag: 'RESET' },
    { name: t('no_internal_id'), tag: 'NoInternalId' },
    { name: t('has_internal_id'), tag: 'HasInternalId' },
    { name: t('no_picutres'), tag: 'NoPictures' },
    { name: t('has_picture'), tag: 'HasPicture' },
    { name: t('has_video'), tag: 'HasVideo' },
    { name: t('packaging_sensitive'), tag: 'PackagingSensitive' },
    { name: t('temperature_sensitive'), tag: 'TemperatureSensitive' },
    { name: t('alcoholic_beverage'), tag: 'AlcoholicBeverage' },
    { name: t('id_required'), tag: 'IdRequired' },
    { name: 'EBT', tag: 'Ebt' },
    { name: t('not_ebt'), tag: 'NotEbt' },
    { name: t('perishable'), tag: 'Perishable' },
    { name: t('not_available'), tag: 'NotAvailable' },
    { name: t('not_visible'), tag: 'NotVisible' },
    { name: t('available'), tag: 'IsAvailable' },
    { name: t('visible'), tag: 'IsVisible' },
  ];
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [refreshSearchFilters, setRefreshSearchFilters] = useRecoilState(refreshSearchFiltersAtom);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [lastSearch, setLastSearch] = useState('');
  const [exportLoading, setExportLoading] = useState(false);
  const [totalInSearch, setTotalInSearch] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [internalDepartments, setInternalDepartments] = useState([]);
  const [selectedInternalDepartment, setSelectedInternalDepartment] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { Text } = Typography;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const exportFields = {
    upc: 'UPC',
    gtin: 'GTIN',
    rank: t('ordering'),
    brand: t('brand'),
    name: t('name'),
    description: t('description'),
    available: t('available'),
    visible: t('visible'),
    sectionName: t('section'),
    superSection: t('department'),
    department: t('group'),
    aisle: t('aisle'),
    unitPriceFactor: t('unit_price_factor'),
    price: t('price'),
    cost: t('cost'),
    pack: t('pack'),
    blockOostrigger: t('block_oos_trigger'),
    oosLimit: t('oos_limit'),
    ebt: 'EBT',
    imageUrl: t('image_url'),
    promoted: t('promoted'),
    idRequired: t('id_required'),
    blockPromoPricing: t('block_promo_pricing'),
    hasRandomWeight: t('has_random_weight'),
    alcoholicBeverage: t('alcoholic_beverage'),
    taxRule: t('tax_rule'),
    baseUnitPrice: t('base_unit_price'),
    baseUnitPriceMethod: t('base_unit_price_method'),
    baseUnitMultiple: t('base_multiple'),
    promoUnitPriceMethod: t('promo_price_method'),
    promoUnitPrice: t('promo_price'),
    promoUnitMultiple: t('promo_multiple'),
    promoStartDate: t('promo_start_date'),
    promoEndDate: t('promo_end_date'),
    munTaxPct: t('municipal_tax'),
    stateTaxPct: t('state_tax'),
    edited: t('edited_on'),
    // created: t('created_at'),
    // lastUpdate: t('last_update'),
  };

  const onFilterClick = function (filter) {
    const tag = filter.tag ? filter.tag : filter.id;
    let newFilterList = [];
    if (tag === defaultFilters[0].tag) {
      newFilterList = [];
    } else {
      const containsFilter = selectedFilters.includes(tag);
      if (containsFilter) {
        newFilterList = _.filter(selectedFilters, (z) => z !== tag);
      } else {
        newFilterList = selectedFilters.concat(tag);
      }
    }
    const tempFilters = JSON.parse(JSON.stringify(filters));
    for (let i = 0; i < tempFilters.length; i++) {
      tempFilters[i].active = false;
      for (let j = 0; j < newFilterList.length; j++) {
        if (tempFilters[i].tag === newFilterList[j] ||
          tempFilters[i].id === newFilterList[j]) {
          tempFilters[i].active = true;
        }
      }
    }
    setFilters(tempFilters);
    setSelectedFilters(newFilterList);
  };

  function mapData(data) {
    console.log('data', data);
    return _.map(data, (p) => ({
      // Base
      key: p.id,
      timeConstraint: p.timeConstraint,
      upc: p.upc,
      gtin: p.gtin,
      rank: p.ordering,
      completeName: `${p.brand} ${p.name} ${p.description}`,
      brand: p.brand,
      name: p.name,
      description: p.description,
      available: p.isAvailable,
      visible: p.isVisible,
      isDeleted: p.isDeleted,
      // Location
      sectionId: p.sectionId,
      sectionName: p.sectionName ? (i18n.language === 'en' ? p.sectionNameEn : p.sectionNameEs) : '',
      superSection: p.superSectionName,
      department: p.department,
      aisle: p.aisle,
      // Prices
      unitPriceFactor: p.unitPriceFactor,
      price: p.activePrice,
      cost: p.cost,
      pack: p.pack,
      blockOostrigger: p.blockOostrigger,
      oosLimit: p.oosLimit,
      currentPrice: p.prices[0].activePrice,
      ebt: p.isEbt,
      purchaseCount: p.historicPurchaseCount,
      imageUrl: p.imageUrl,
      promoted: p.isPromoted,
      madeInPR: p.madeLocally,
      idRequired: p.idRequired,
      blockPromoPricing: p.blockPromoPricing,
      // Other Flags
      hasRandomWeight: p.hasRandomWeight,
      alcoholicBeverage: p.isAlcoholicBeverage,
      // Flags
      organic: p.isOrganic,
      vegan: p.isVegan,
      vegetarian: p.isVegetarian,
      glutenFree: p.isGlutenFree,
      antioxidant: p.isAntioxidant,
      keto: p.isKeto,
      stepAmount: p.stepAmount,
      sugarFree: p.isSugarFree,
      lactoseFree: p.isLactoseFree,
      nonGmo: p.isNonGMO,
      nutFree: p.isNutFree,
      grassFed: p.isGrassFed,
      natural: p.isAllNatural,
      customDepartment01: p.customBooleanProperty01,
      customDepartment02: p.customBooleanProperty02,
      customDepartment03: p.customBooleanProperty03,
      minQuantity: p.minQuantity,
      maxQuantity: p.maxQuantity,
      perishable: p.isPerishable,
      taxRule: p.taxRule,

      baseUnitPrice: p.baseUnitPrice,
      baseUnitPriceMethod: p.baseUnitPriceMethod,
      baseUnitMultiple: p.baseUnitMultiple,

      promoUnitPriceMethod: p.promoUnitPriceMethod,
      promoUnitPrice: p.promoUnitPrice,
      promoUnitMultiple: p.promoUnitMultiple,
      promoStartDate: p.promoStartDate,
      promoEndDate: p.promoEndDate,

      munTaxPct: p.munTaxPct * 100,
      stateTaxPct: p.stateTaxPct * 100,
      edited: moment(`${p.editedAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      created: moment(`${p.createdAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
      lastUpdate: moment(`${p.updatedAt}+0000`)
        .tz(timezone)
        .format('MMMM Do YYYY, h:mm:ss a'),
    }));
  }

  const exportData = function () {
    const data = {
      IsAssigned: showAssigned,
      DepartmentId: selectedDepartment,
      SectionId: selectedSection,
      InternalDepartment: selectedInternalDepartment,
      Query: `${lastSearch}`,
      Filters: selectedFilters
    };
    setExportLoading(true);
    api
      .post(
        `businesses/catalog/export/${store?.id}/search`,
        data
      )
      .then((response) => {
        const mapped = _.map(response.data.data.results, (p) => ({
          upc: p.upc,
          gtin: p.gtin,
          rank: p.ordering,
          brand: p.brand,
          name: p.name,
          description: p.description,
          available: p.isAvailable,
          visible: p.isVisible,
          sectionName: p.sectionName ? (i18n.language === 'en' ? p.sectionNameEn : p.sectionNameEs) : '',
          superSection: p.superSectionName ? p.superSectionName : '',
          department: p.department ? p.department : '',
          aisle: p.aisle ? p.aisle : '',
          unitPriceFactor: p.unitPriceFactor,
          price: p.activePrice,
          cost: p.cost,
          pack: p.pack,
          blockOostrigger: p.blockOostrigger,
          oosLimit: p.oosLimit,
          currentPrice: p.prices && p.prices?.length > 0 ?
            p.prices[0].activePrice : p.baseUnitPrice,
          ebt: p.isEbt,
          imageUrl: p.imageUrl,
          promoted: p.isPromoted,
          idRequired: p.idRequired,
          blockPromoPricing: p.blockPromoPricing,
          hasRandomWeight: p.hasRandomWeight,
          alcoholicBeverage: p.isAlcoholicBeverage,
          taxRule: p.taxRule,
          baseUnitPrice: p.baseUnitPrice,
          baseUnitPriceMethod: p.baseUnitPriceMethod,
          baseUnitMultiple: p.baseUnitMultiple,
          promoUnitPriceMethod: p.promoUnitPriceMethod,
          promoUnitPrice: p.promoUnitPrice,
          promoUnitMultiple: p.promoUnitMultiple,
          promoStartDate: p.promoStartDate,
          promoEndDate: p.promoEndDate,
          munTaxPct: p.munTaxPct * 100,
          stateTaxPct: p.stateTaxPct * 100,
          edited: `${moment(`${p.editedAt}+0000`)
            .tz(timezone)
            .format('MMMM Do YYYY, h:mm:ss a')}`,
          // created: `${moment(`${p.createdAt}+0000`)
          //   .tz(timezone)
          //   .format('MMMM Do YYYY, h:mm:ss a')}`,
          // lastUpdate: `${moment(`${p.updatedAt}+0000`)
          //   .tz(timezone)
          //   .format('MMMM Do YYYY, h:mm:ss a')}`
        }));
        setExportLoading(false);
        saveAsCsv({
          data: mapped,
          fields: exportFields,
          filename: `${store?.name}_search_export_${new Date().toLocaleDateString()}`,
        });
        // saveAsCsv({
        //   mapped: [{ upc: '123' }],
        //   fields: { upc: 'UPC' },
        //   filename: `${store?.name}_search_export_${new Date().toLocaleDateString()}`,
        // });
      })
      .catch((error) => {
        console.error(error);
        setExportLoading(false);
      });
  };

  const searchItems = function (value, p) {
    setLastSearch(value);
    setLoading(true);
    setSelectedItems([]);
    const data = {
      Page: p,
      Size: size,
      IsAssigned: showAssigned,
      DepartmentId: selectedDepartment,
      SectionId: selectedSection,
      InternalDepartment: selectedInternalDepartment,
      Query: `${value}`,
      Filters: selectedFilters
    };
    api
      .post(
        `businesses/catalog/${store?.id}/search`,
        data
      )
      .then((response) => {
        const mapped = mapData(response.data.data.results);
        setTotalInSearch(parseInt(response.data.internalMessage, 10));
        setResults(mapped);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const onSearch = (value) => {
    setPage(0);
    searchItems(value, 0);
  };

  const updateItems = function (model) {
    setLoading(true);
    api
      .post('businesses/catalog/itemupdate', {
        ...model,
        ids: selectedItems
      })
      .then((response) => {
        setLoading(false);
        searchItems(lastSearch, page);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const fetchFilters = function () {
    setIsLoadingFilters(true);
    setFilters([]);
    api
      .get('businesses/catalog/filters')
      .then((response) => {
        if (response.data.success) {
          setIsLoadingFilters(false);
          const tempFilters = defaultFilters.concat(response.data.data.results);
          for (let i = 0; i < selectedFilters; i++) {
            for (let j = 0; j < tempFilters; j++) {
              if (tempFilters[j].id === selectedFilters[i] ||
                tempFilters[j].tag === selectedFilters[i]) {
                tempFilters[j].active = true;
              }
            }
          }
          setFilters(tempFilters);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsLoadingFilters(false);
        showMessage(error);
      });
  };

  const fetchHeaders = function () {
    setInternalDepartments([]);
    setDepartments([]);
    api
      .get(`businesses/catalog/search/headers/${store?.id}`)
      .then((response) => {
        if (response.data.success) {
          setInternalDepartments(response.data.data.internalDepartments);
          setDepartments(response.data.data.departments);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        showMessage(error);
      });
  };

  useEffect(() => {
    onSearch(lastSearch);
  }, [selectedFilters]);

  useEffect(() => {
    if (refreshSearchFilters) {
      fetchFilters();
      setRefreshSearchFilters(false);
    }
  }, [refreshSearchFilters]);

  useEffect(() => {
    if (store) {
      fetchFilters();
      fetchHeaders();
    }
  }, []);

  useEffect(() => {
    if (store) {
      fetchFilters();
      fetchHeaders();
    }
  }, [store]);

  useEffect(() => {
    onSearch(lastSearch);
  }, [showAssigned, selectedDepartment, selectedInternalDepartment, selectedSection]);

  useEffect(() => {
    setSelectedSection(null);
  }, [selectedDepartment]);

  return (
    <div className="m-4 mt-4">
      <div className={`flex bg-orange-500 text-white rounded px-4 mb-2 justify-items-start w-auto flex-col-1 ${store && store?.id ? 'hidden' : 'block'}`}>{t('no_store_selected_warning')}</div>
      <div className={`flex bg-red-600 text-white rounded px-4 mb-4 justify-items-start w-auto flex-col-1 ${businessProfile && businessProfile?.canEditMenuItems ? 'hidden' : 'block'}`}>{t('cant_edit_products_warning')}</div>
      {<div>{businessProfile && businessProfile?.canEditMenuItems}</div>}
      {lastSearch && (
        <div className="mb-2 ml-2 hidden">
          <div>{t('last_search')}: </div>
          <div className="ml-2 text-blue-600">
            <Typography.Text copyable>{lastSearch}</Typography.Text>
          </div>
        </div>
      )}
      <div>
        {isLoadingFilters && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
        <div style={{ marginBottom: 10 }}>
          {!isLoadingFilters && filters && _.map(filters, (filter, index) => (
            <Tag
              key={`${index}${filter.tag}`}
              onClick={() => onFilterClick(filter)}
              color={filter.active ? '#1890ff' : ''}
              style={{ color: filter.active ? '#333333' : '', display: 'inline-block', marginBottom: '10px' }}
            >
              {filter.name}
            </Tag>
          ))}
        </div>
      </div>
      <div>
        <Input.Search
          placeholder={t('search_description')}
          allowClear
          enterButton={t('search')}
          size="large"
          onSearch={onSearch}
        />
      </div>
      <div className="my-4 border rounded border-gray-400">
        <Row className="py-4">
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            <div className="ml-2 flex flex-col">
              <span className="mr-2 m-1 font-semibold">{t('department')}</span>
              <Select
                style={{ width: 200 }}
                onChange={(value) => setSelectedDepartment(value)}
                value={selectedDepartment}
              >
                <Option value={null}>{t('any')}</Option>
                {_.map(_.sortBy(departments, [(n) => n.nameEng]), (d) => (
                  <Option value={d.id}>{i18n.language === 'en' ? d.nameEng : d.nameSpa}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            <div className="ml-2 flex flex-col">
              <span className="mr-2 m-1 font-semibold">{t('category')}</span>
              <Select
                style={{ width: 200 }}
                disabled={!selectedDepartment}
                onChange={(value) => setSelectedSection(value)}
                value={selectedSection}
              >
                <Option value={null}>{t('any')}</Option>
                {selectedDepartment && departments.length > 0 && _.map(_.filter(departments, (d) =>
                  d.id === selectedDepartment)[0].categories, (s) => (
                    <Option value={s.id}>{i18n.language === 'en' ? s.nameEng : s.nameSpa}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            <div className="ml-2 flex flex-col">
              <div className="m-1">
                <span className="mr-1 font-semibold">{t('group')}</span>
                <Tooltip title={t('product_group_helper')}>
                  <QuestionCircleOutlined className="text-xs ml-1 text-green-600" />
                </Tooltip>
              </div>
              <Select
                style={{ width: 150 }}
                onChange={(value) => setSelectedInternalDepartment(value)}
                value={selectedInternalDepartment}
              >
                <Option value="">{t('any')}</Option>
                {_.map(_.sortBy(internalDepartments, [(i) => i]), (d) => (
                  <Option value={d}>{d}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5}>
            {' '}
            <Button
              loading={exportLoading}
              disabled={loading}
              size="small"
              type="primary"
              onClick={() => exportData()}
              icon={<CloudDownloadOutlined />}
            >
              {t('export')}
            </Button>
            <div className="mr-2 mt-2 flex flex-row">
              <Switch
                size="small"
                className="ml-2 mr-2 mt-2"
                onChange={(checked) => {
                  setShowAssigned(checked);
                }}
                checked={showAssigned}
              />
              <div className="m-1">
                <span className="mr-1 font-semibold">{t('categorized_products')}</span>
                <Tooltip title={t('categorized_filter_helper')}>
                  <QuestionCircleOutlined className="text-xs ml-1 text-green-600" />
                </Tooltip>
              </div>
            </div>
          </Col>
          {lastSearch && (
            <div className="mx-2 hidden">{t('results')}: {results && results.length}</div>
          )}
        </Row>
      </div>
      <br />
      <div className={`mx-2 ${lastSearch !== '' ? 'block' : 'hidden'}`}>
        {results && <div className="mt-0 ml-0">{`Showing results for "${lastSearch}": ${totalInSearch.toLocaleString()}`}</div>}
      </div>
      <div className={`mx-2 ${lastSearch === '' ? 'block' : 'hidden'}`}>
        {results && <div className="mt-0 ml-0">Matches: {totalInSearch.toLocaleString()}</div>}
      </div>
      <div className="mt-8">
        <div className="flex mb-2">
          <Button
            className="mr-2 ml-auto"
            hidden={selectedItems.length === 0}
            size="small"
            type="primary"
            onClick={() => updateItems({
              visible: true
            })}
          >
            {t('set_visible')}
          </Button>
          <Button
            className="mr-2"
            size="small"
            type="primary"
            hidden={selectedItems.length === 0}
            onClick={() => updateItems({
              visible: false
            })}
          >
            {t('set_not_visible')}
          </Button>
          <Button
            className="mr-2"
            size="small"
            type="primary"
            hidden={selectedItems.length === 0}
            onClick={() => updateItems({
              available: true
            })}
          >
            {t('set_available')}
          </Button>
          <Button
            className="mr-2"
            size="small"
            type="primary"
            hidden={selectedItems.length === 0}
            onClick={() => updateItems({
              available: false
            })}
          >
            {t('set_not_available')}
          </Button>
        </div>
        <Table
          size="small"
          loading={loading}
          bordered
          rowKey="key"
          rowSelection={{
            selectedRowKeys: selectedItems,
            onChange: (newSelectedRowKeys) => setSelectedItems(newSelectedRowKeys)
          }}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: size,
            defaultCurrent: 0,
            current: page + 1,
            total: totalInSearch,
            onChange: (p, s) => {
              if (p - 1 !== page) {
                setPage(p - 1);
                searchItems(lastSearch, p - 1);
              } else {
                setSize(s);
              }
            }
          }}
          columns={[
            {
              title: 'Upc',
              dataIndex: 'upc',
              key: 'upc',
              align: 'left',
              className: 'text-xs',
              render: (text) => (
                <Typography.Text copyable>{text}</Typography.Text>
              ),
            },
            {
              title: t('brand'),
              dataIndex: 'brand',
              key: 'brand',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('name'),
              dataIndex: 'name',
              key: 'name',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('description'),
              dataIndex: 'description',
              key: 'description',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('department'),
              dataIndex: 'superSection',
              key: 'department',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('category'),
              dataIndex: 'sectionName',
              key: 'sectionName',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('group'),
              dataIndex: 'department',
              key: 'group',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('price'),
              dataIndex: 'price',
              key: 'price',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text ? <Text>${text.toFixed(2)}</Text> : <Text type="danger">$0.00</Text>}</span>,
            },
            {
              title: t('cost'),
              dataIndex: 'cost',
              key: 'price',
              align: 'left',
              className: 'text-xs hidden',
              render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
            },
            {
              title: t('available'),
              dataIndex: 'available',
              key: 'available',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>
                  <Switch size="small" disabled checked={text} />
                </span>
              ),
            },
            {
              title: 'Visible',
              dataIndex: 'visible',
              key: 'visible',
              align: 'center',
              className: 'text-xs',
              render: (text) => (
                <span>
                  <Switch size="small" disabled checked={text} />
                </span>
              ),
            },
            {
              title: 'DL',
              key: '',
              align: 'center',
              className: 'text-xs',
              render: (text, record) => (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(`${envConfig.SpecificProductUrl}/${record.key}`);
                    }}
                  >
                    <LinkOutlined />
                  </Button>
                </>
              ),
            },
            {
              title: t('edit'),
              key: 'edit',
              align: 'center',
              className: 'text-xs',
              render: (text, record) => (
                <Button
                  disabled={
                    !isAdmin && !isPanza && !businessProfile?.canEditMenuItems
                  }
                  size="small"
                  onClick={() => {
                    setProduct(record);
                  }}
                >
                  {t('edit')}
                </Button>
              ),
            },
          ]}
          dataSource={results && results.length > 0 ? results : null}
          expandable={{
            expandRowByClick: false,
            expandedRowRender: (record) => (
              <div>
                <Tabs>
                  <Tabs.TabPane className="bg-white" tab={t('details')} key="1">
                    <br />
                    <div className="bg-white">
                      <div className="grid grid-cols-3 gap-4 px-2">
                        <div>
                          <Image
                            width={30}
                            src={record.imageUrl}
                          />
                        </div>

                        <div>
                          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                            <h1 className="text-xl font-bold tracking-tight text-gray-900">{record.brand}</h1>
                            <div className="mt-3">
                              <p className="text-lg tracking-tight text-gray-900">{record.name}</p>
                              <p className="text-lg tracking-tight text-gray-900">{record.description}</p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Disclosure as="div" key={t('categorization')}>
                            <>
                              <h3>
                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                  <span
                                    className={classNames(
                                      open ? 'text-indigo-600' : 'text-gray-900',
                                      'text-sm font-medium'
                                    )}
                                  >
                                    {t('categorization')}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusOutlined
                                        className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusOutlined
                                        className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                <ul style={{ listStyleType: 'disc' }}>
                                  <li key={t('department')}><b>{t('department')}</b>: {record.department}</li>
                                  <li key={t('category')}><b>{t('category')}</b>: {record.category}</li>
                                  <li key={t('aisle')}>
                                    <b>{t('aisle')}: </b>
                                    {record.aisle ? record.aisle : t('no_aisle_assigned')}
                                  </li>
                                  <li key={t('random_weight')}>
                                    <b>{t('random_weight')} </b>
                                    <Switch
                                      disabled
                                      size="small"
                                      checked={record.hasRandomWeight}
                                    />
                                  </li>
                                  <li key={t('purchased')}><b>{t('purchased')}: </b>{record.purchaseCount}</li>
                                  <li key={t('rank')}><b>{t('rank')}: </b>{record.rank}</li>
                                </ul>
                              </Disclosure.Panel>
                            </>
                          </Disclosure>
                          <Disclosure as="div" key={t('updates')}>
                            <>
                              <h3>
                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                  <span
                                    className={classNames(
                                      open ? 'text-indigo-600' : 'text-gray-900',
                                      'text-sm font-medium'
                                    )}
                                  >
                                    {t('updates')}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusOutlined
                                        className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusOutlined
                                        className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                <ul style={{ listStyleType: 'disc' }}>
                                  <li key={t('created')}><b>{t('created')}</b>: {record.created}</li>
                                  <li key={t('store_update')}><b>{t('store_update')}</b>: {record.edited}</li>
                                  <li key={t('pos_update')}><b>{t('pos_update')}</b>: {record.lastUpdate}</li>
                                </ul>
                              </Disclosure.Panel>
                            </>
                          </Disclosure>
                          <Disclosure as="div" key={t('pricing')}>
                            <>
                              <h3>
                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                  <span
                                    className={classNames(
                                      open ? 'text-indigo-600' : 'text-gray-900',
                                      'text-sm font-medium'
                                    )}
                                  >
                                    {t('pricing')}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusOutlined
                                        className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusOutlined
                                        className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                <ul style={{ listStyleType: 'disc' }}>
                                  <li key={t('price')}><b>{t('price')}</b>: {record.currentPrice.toFixed(2)}</li>
                                  <li key={t('tax')}><b>Tax</b>: {convertTaxRule(record.taxRule)}</li>
                                </ul>
                              </Disclosure.Panel>
                            </>
                          </Disclosure>
                        </div>
                      </div>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Flags" key="2">
                    <div className="p-4">
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.ebt}
                            />
                          </span>
                          <Typography.Text> Ebt </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.alcoholicBeverage}
                            />
                          </span>
                          <Typography.Text>
                            {' '}
                            {t('alcoholic_beverage')}{' '}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.promoted}
                            />
                          </span>
                          <Typography.Text> {t('promoted')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.madeInPR}
                            />
                          </span>
                          <Typography.Text> {t('made_in_pr')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.idRequired}
                            />
                          </span>
                          <Typography.Text> {t('id_required')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.blockPricing}
                            />
                          </span>
                          <Typography.Text> {t('block_pricing')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.catalog}
                            />
                          </span>
                          <Typography.Text> {t('catalog')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.customDepartment01}
                            />
                          </span>
                          <Typography.Text>
                            {' '}
                            {t('custom_department')} 01{' '}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.customDepartment02}
                            />
                          </span>
                          <Typography.Text>
                            {' '}
                            {t('custom_department')} 02{' '}
                          </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.customDepartment03}
                            />
                          </span>
                          <Typography.Text>
                            {' '}
                            {t('custom_department')} 03{' '}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.oosTriggerBlock}
                            />
                          </span>
                          <Typography.Text> {t('oos_trigger_block')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.perishable}
                            />
                          </span>
                          <Typography.Text> {t('perishable')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.organic}
                            />
                          </span>
                          <Typography.Text> {t('organic')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.vegan}
                            />
                          </span>
                          <Typography.Text> {t('vegan')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.vegetarian}
                            />
                          </span>
                          <Typography.Text> {t('vegetarian')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.glutenFree}
                            />
                          </span>
                          <Typography.Text> {t('gluten_free')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.antioxidant}
                            />
                          </span>
                          <Typography.Text> {t('antioxidant')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.keto}
                            />
                          </span>
                          <Typography.Text> Keto </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.sugarFree}
                            />
                          </span>
                          <Typography.Text> {t('sugar_free')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.lactoseFree}
                            />
                          </span>
                          <Typography.Text> {t('lactose_free')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.nonGmo}
                            />
                          </span>
                          <Typography.Text> Non GMO </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.nutFree}
                            />
                          </span>
                          <Typography.Text> {t('nut_free')} </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.grassFed}
                            />
                          </span>
                          <Typography.Text> {t('grass_fed')} </Typography.Text>
                        </Col>
                        <Col span={8} offset={2}>
                          <span>
                            <Switch
                              disabled
                              size="small"
                              loading={loading && record.key === key}
                              checked={record.natural}
                            />
                          </span>
                          <Typography.Text> Natural </Typography.Text>
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="3" tab={t('pricing')}>
                    <div className="flex flex-columns justify-content">
                      <div className="p-8">
                        <Descriptions>
                          <Descriptions.Item label={t('has_random_weight')}>
                            <Typography.Text>
                              {record.hasRandomWeight ? t('yes') : 'No'}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label={t('unit_price_factor')}>
                            <Typography.Text>
                              {record.unitPriceFactor}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label="Tax Rule ">
                            <Typography.Text>
                              {t(convertTaxRule(record.taxRule))}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label="Step Amount ">
                            <Typography.Text>
                              {record.stepAmount}
                            </Typography.Text>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="p-8">
                        <Descriptions>
                          <Descriptions.Item label={t('base_unit_price')}>
                            <Typography.Text>
                              ${record.baseUnitPrice}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label={t('base_unit_price_method')}>
                            <Typography.Text>
                              {record.baseUnitPriceMethod}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label="Municipal Tax">
                            <Typography.Text>
                              {record.munTaxPct.toFixed(2)}%
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label="State Tax">
                            <Typography.Text>
                              {record.stateTaxPct.toFixed(2)}%
                            </Typography.Text>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="p-8">
                        <Descriptions>
                          <Descriptions.Item label={t('promo_start_date')}>
                            <Typography.Text>
                              {record.promoStartDate}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label={t('promo_end_date')}>
                            <Typography.Text>
                              {record.promoEndDate}
                            </Typography.Text>
                          </Descriptions.Item>
                          <br />
                          <br />
                          <Descriptions.Item label={t('promo_price_method')}>
                            <Typography.Text>
                              {record.promoUnitPriceMethod}
                            </Typography.Text>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            ),
            rowExpandable: (record) => record,
          }}
        />
      </div>
    </div>
  );
}

export default Search;
